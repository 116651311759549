(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var Tabs = function Tabs() {
  /**
   * All tabs DOM elements.
   */
  var tabs = document.querySelectorAll('[data-tabs-role="tab"]');
  /**
   * Initialization start.
   */

  var init = function init() {
    initStyles();
    addEventForTabs(tabs);
  };
  /**
   * Adding functionality to tabs click event.
   * 
   * @param {array} tabs - Array of tabs DOM elements 
   */


  var addEventForTabs = function addEventForTabs(tabs) {
    tabs.forEach(function (tab) {
      tab.addEventListener('click', function () {
        var area = document.querySelector("".concat(tab.getAttribute('data-tabs-href'))),
            tabSiblings = utils.getSiblings(tab),
            areaSiblings = utils.getSiblings(area);
        utils.removeElementsClass(tabSiblings, 'active');
        utils.removeElementsClass(areaSiblings, 'active');
        utils.addClass(tab, 'active');
        utils.addClass(area, 'active');
      });
    });
  };
  /**
   * Declare css styles.
   */


  var initStyles = function initStyles() {
    var style = document.createElement('style');
    style.innerHTML = "\n      [data-tabs-role=\"area\"]:not(.active) {\n          display: none !important;\n      }\n      [data-tabs-role=\"area\"].active {\n          display: inherit;\n      }\n    ";
    document.getElementsByTagName('head')[0].appendChild(style);
  };
  /**
   * Automatically script initialization.
   */


  init();
};
/**
* Load script after window load.
*/


window.addEventListener('load', Tabs);
/**
* Auxillary functions.
*/

var utils = {
  /**
   * Getting DOM element siblings.
   * 
   * @param {object} element - Target DOM element
   * @returns {array} - Siblings array
   */
  getSiblings: function getSiblings(element) {
    var siblings = [],
        sibling = element.parentNode.firstChild;

    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== element) {
        siblings.push(sibling);
      }

      sibling = sibling.nextSibling;
    }

    return siblings;
  },

  /**
   * Removing html classname from each element in array.
   * 
   * @param {array} elements - Array of target DOM elements
   * @param {string} className - Received classname
   * @returns {void} - Nothing
   */
  removeElementsClass: function removeElementsClass(elements, className) {
    elements.forEach(function (element) {
      element.classList.remove(className);
    });
  },

  /**
   * Adding html classname to target DOM element.
   * 
   * @param {object} element - Target DOM element 
   * @param {string} className - Received classname
   */
  addClass: function addClass(element, className) {
    element.classList.add(className);
  }
};

},{}]},{},[1]);
